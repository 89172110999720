<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        题目管理
      </template>
      <template #input>
        <a-input placeholder="请输入题干" v-model="title" />
        <a-select placeholder="请选择类型" v-model="type">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="null"> 全部 </a-select-option>

          <a-select-option value="1">单选</a-select-option>
          <a-select-option value="2">多选</a-select-option>
          <a-select-option value="3">判断</a-select-option>
        </a-select>
        <a-select placeholder="请选择难度" v-model="difficulty">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="null"> 全部 </a-select-option>

          <a-select-option value="1">简单</a-select-option>
          <a-select-option value="2">一般</a-select-option>
          <a-select-option value="3">困难</a-select-option>
        </a-select>
        <a-button type="primary" class="btn" :loading="loading" @click="search">搜索</a-button>
      </template>
    </HeaderBox>
    <div class="function">
      <a-tabs default-active-key="1" @change="callback" v-model="category">
        <a-tab-pane :key="1" tab="考试"></a-tab-pane>
        <a-tab-pane :key="2" tab="模拟考试"></a-tab-pane>
        <a-tab-pane :key="3" tab="小节自测"></a-tab-pane>
      </a-tabs>
      <div>
        <a-button type="primary" class="btn" @click="relatedChapters">关联章节</a-button>
        <a-button type="primary" class="btn" @click.native="weightRemoval">排重</a-button>
        <a-upload
          :showUploadList="false"
          name="file"
          :customRequest="fileAction"
        >
          <a-button type="primary">批量导入</a-button>
        </a-upload>
        <a
          :href="this.$config.target + '/hxclass-management/exam/library/getSectionTemplate?courseId=' + courseId"
        >
          <a-button class="all_boder_btn" style="margin-left:20px;margin-right: 0;">下载章节模板</a-button>
        </a>
        <a
          href="https://cos.hxclass.cn/prod/template/exam/%E9%A2%98%E7%9B%AE%E6%A8%A1%E6%9D%BF.xls"
        >
          <a-button class="all_boder_btn" style="margin-left:20px;">下载模板</a-button>
        </a>
        <a-button class="all_boder_btn" @click="addPage">新建</a-button>
      </div>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <div class="table-left">
        <div class="item" v-for="(item,index) in courseList" :key="index">
          <p class="item-name" :class="{'item-name-active':item.isOpen}" :title="item.courseName" @click="changeCourse(item,index)"><a-icon type="caret-down" v-if="item.isOpen" /><a-icon type="caret-right" v-else />{{item.courseName}}</p>
          <div v-show="item.isOpen">
            <a-tree
              class="draggable-tree"
              @select="treeSelect"
              :selectedKeys="tableselectedKeys"
              :tree-data="item.children"
              :replaceFields="{children:'childList', title:'sectionName', key:'sectionId'}"
            />
          </div>
        </div>
      </div>
      <a-table
        class="table-right"
        :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: select,
        onSelectAll: selectAll,
        columnTitle:'选择'
        }"
        :rowKey="item=>item.libraryId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="footer" v-if="tableData.length">
          <a-checkbox @change="onChange" :checked="isChecked" class="allSellect">
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="sectionName" slot-scope="text">
          {{text?text:'-'}}
        </template>
        <template slot="operation" slot-scope="text,record">
          <div class="btn_router_link">
            <router-link to @click.native="$router.push('/admin/Examination/AddTestQuestions?category='+category+'&libraryId='+record.libraryId+'&courseId='+courseId)">编辑</router-link>
            <span>|</span>
            <router-link to @click.native="delManageItem(record.libraryId)">删除</router-link>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 关联章节 -->
    <a-modal v-model="ChapterVisibel" align="center" title="关联章节" @ok="bindSection" @cancel="selectedKeys=[],ChapterVisibel=false" >
      <div style="text-align:left">
        <a-tree
          @select="sectioncheck"
          :defaultExpandAll="true"
          :auto-expand-parent="true"
          :selected-keys="selectedKeys"
          :tree-data="sectionList"
          :replaceFields="{children:'childList', title:'sectionName', key:'sectionId'}"
        />
      </div>
    </a-modal>
     <!-- 排重 -->
    <a-modal v-model="RemovalVisibel" @cancel="cancelRemoval" align="center" title="排重"  width='700px'>
      <template #footer>
        <a-button class="all_boder_btn" @click="cancelRemoval">取消</a-button>
      </template>
      <div class="duplicate" style="text-align:left">
        <a-table
        class="table-right"
        :rowKey="item=>item.id"
        :columns="columns2"
        :data-source="duplicateData"
        @change="onPageduplicate"
        :pagination="{
          total:duplicatetotal,
          current:pageduplicateNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="operation" slot-scope="text,record">
          <div class="btn_router_link">
            <router-link to @click.native="delManageItem(record.libraryId)">删除</router-link>
            <span>|</span>
            <router-link to @click.native="$router.push('/admin/Examination/AddTestQuestions?category='+category+'&libraryId='+record.libraryId+'&courseId='+record.courseId)">编辑</router-link>
          </div>
        </template>
      </a-table>
      </div>
    </a-modal>
    <LeadingIn :columns="importColumn" :successList="successList" :failureList="failureList" :visible="importVisible" :handleCancel="modelhandleCancel"/>
  </div>
</template>

<script>
const treeData = [
  {
    title: '第一章：孤独症儿童症状',
    key: '0-0',
    children: [
      {
        title: '1.1 孤独症儿童的介绍',
        key: '0-0-0',
        children: [
          { title: '1.1.1 孤独症儿童的介绍', key: '0-0-0-0' },
          { title: '1.1.2 孤独症儿童的介绍', key: '0-0-0-1' },
          { title: '1.1.3 孤独症儿童的介绍', key: '0-0-0-2' },
        ],
      },
      {
        title: '1.2 孤独症儿童的介绍',
        key: '0-0-1',
        children:null
      },
    ],
  }
];
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "题干",
    ellipsis: 'true',
    align:'center',
    dataIndex: "title"
  },
  {
    title: "章节",
    align:'center',
    dataIndex: "sectionName",
    scopedSlots: { customRender: "sectionName" }
  },
  {
    title: "难度",
    align:'center',
    dataIndex: "difficulty"
  },
  {
    title: "类型",
    align:'center',
    dataIndex: "type"
  },
  {
    title: "操作",
    align:'center',
    width: "200px",
    scopedSlots: { customRender: "operation" }
  }
];

const columns2 = [
  {
    title: "题干",
    align:'center',
    dataIndex: "title"
  },
  {
    title: "课程",
    align:'center',
    dataIndex: "courseName"
  },
  {
    title: "题目归属",
    align:'center',
    dataIndex: "sectionName"
  },
  {
    title: "操作",
    align:'center',
    width: "200px",
    scopedSlots: { customRender: "operation" }
  }
];

import HeaderBox from '@/components/HeaderBox.vue'
import LeadingIn from '@/components/leadingIn.vue' // 导入组件

export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      optionList:['A','B','C','D','E','F','G'],
      courseId:'', // 课程id
      sectionId:'', // 章节id
      sectionList:'', // 章节列表
      title:'', // 标题
      difficulty:undefined, // 难度
      type:undefined, // 类型 1.单选 2.多选 3.判断
      category:1, // 大类 1.考试 2.模拟考试 3.小节自测
      columns, // 表头
      columns2,
      treeData,
      tableselectedKeys: [],
      selectedKeys: [],
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      isChecked: false, 
      selectedRowKeys:[],
      ChapterVisibel:false,
      RemovalVisibel:false,
      duplicateData:[], // 重复数据
      pageduplicateNumber:1,
      pageduplicateSize:10,
      duplicatetotal:0,
      courseList:[], // 课程列表
      importVisible:false, // 导入弹框
      successList:null, // 正确数据
      failureList:null, // 错误数据
      importColumn:[
        {
          title: "题目",
          align:'center',
          dataIndex: "title"
        },
        {
          title: "正确答案",
          align:'center',
          dataIndex: "correct"
        },
        {
          title: "题目难度",
          align:'center',
          dataIndex: "difficulty"
        },
        {
          title: "题目类型",
          align:'center',
          dataIndex: "type"
        },
        {
          title: "解析",
          align:'center',
          dataIndex: "answer"
        },
        {
          title: "是否可打乱选项顺序",
          align:'center',
          dataIndex: "isFixed"
        },
        {
          title: "A",
          align:'center',
          dataIndex: "A"
        },
        {
          title: "B",
          align:'center',
          dataIndex: "B"
        },
        {
          title: "C",
          align:'center',
          dataIndex: "C"
        },
        {
          title: "D",
          align:'center',
          dataIndex: "D"
        },
        {
          title: "E",
          align:'center',
          dataIndex: "E"
        },
        {
          title: "F",
          align:'center',
          dataIndex: "F"
        },
        {
          title: "G",
          align:'center',
          dataIndex: "G"
        },
      ],
      // menuList:[
      //   {
      //     key:'1',
      //     isOpen:false,
      //     name:'孤独症课程',
      //     childList:[
      //       { title:'第一张',key:'1-1' },
      //       { title:'第二章',key:'1-2' },
      //       { title:'第二章',key:'1-3' },
      //     ]
      //   },
      //   {
      //     key:'2',
      //     isOpen:false,
      //     name:'孤独症课程',
      //     childList:[
      //       { title:'第一张',key:'2-1' },
      //       { title:'第二章',key:'2-2' },
      //       { title:'第二章',key:'2-3' },
      //     ]
      //   },
      //   {
      //     key:'3',
      //     isOpen:false,
      //     name:'孤独症课程',
      //     childList:[
      //       { title:'第一张',key:'3-1' },
      //       { title:'第二章',key:'3-2' },
      //       { title:'第二章',key:'3-3' },
      //     ]
      //   },
      //   {
      //     key:'4',
      //     isOpen:false,
      //     name:'孤独症课程',
      //     childList:[
      //       { title:'第一张',key:'4-1' },
      //       { title:'第二章',key:'4-2' },
      //       { title:'第二章',key:'4-3' },
      //     ]
      //   },
      //   {
      //     key:'5',
      //     isOpen:false,
      //     name:'孤独症课程',
      //     childList:[
      //       { title:'第一张',key:'1' },
      //       { title:'第二章',key:'2' },
      //       { title:'第二章',key:'3' },
      //     ]
      //   }
      // ]
    }
  },
  // 事件处理器
  methods: {
    // 下载错误excel
    downloadExcel(){
      if(!this.failureList.length){
        return
      }
      // window.open(this.$config.target + '/hxclass-management/exam/library/export/error/list?errorJson=' + encodeURIComponent(JSON.stringify(this.failureList)))
       this.$ajax({
        url: "/hxclass-management/exam/library/export/error/list",
        method: "post",
        headers: [{type: 'file'}],
        params: {
          errorJson: JSON.stringify(this.failureList)
        },
        responseType: 'blob'
      }).then((res) => {
        this.blobDownload(res,'错误文件')
      });
    },
     // blob文件转excel
    blobDownload(data,name){
      let m=this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content],{type:"application/vnd.ms-excel;charset=utf-8"});
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name+".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },

    // 导入正确数据
    importSuccessList(){
      let libraryJson = this.successList.map(item=>{
        let arr = [];
        for(let i=0;i<this.optionList.length;i++){
          if(item[this.optionList[i]]){
            arr.push({
              isCorrect: item.correct.indexOf(this.optionList[i]) != -1?1:0,
              name: item[this.optionList[i]]
            })
          }
        }
        return {
          difficulty: item.difficulty == '简单'?1:item.difficulty == '一般'?2:3,
          type: item.type == '单选题'?1:item.type == '多选题'?2:3,
          isFixed: item.isFixed == '可打乱选项'?0:1,
          title: item.title,
          answer: item.answer,
          category:this.category, // 大类 1.考试 2.模拟考试 3.小节自测
          courseId:this.courseId,
          sectionId:item.sectionId,
          optionJson: JSON.stringify(arr)
        }
      })
      this.$ajax({
        url:"/hxclass-management/exam/library/batch/save",
        method:"post",
        params:{
          libraryJson:JSON.stringify(libraryJson)
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.importVisible = false; // 导入弹框
          this.successList = null; // 正确数据
          this.failureList = null; // 错误数据
          this.getManageList();
        }
      })
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/exam/library/import?courseId="+this.courseId+"&sectionId="+this.sectionId,
        method: "POST",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          name: "file"
        },
      }).then((res) => {
        if(res.code == 200 && res.success){
          this.successList = res.data.successList;
          this.failureList = res.data.failureList;
          this.importVisible = true;
        }
      });
    },

    // // 下载错误excel
    // downloadExcel() {
    //   if (!this.failureList.length) {
    //     return;
    //   }
    //   window.open(
    //     this.$config.target +
    //       "/hxclass-management/exam/certif/export/certifinfo?errorJson=" +
    //       encodeURIComponent(JSON.stringify(this.failureList)) +
    //       "&type=2"
    //   );
    // },

    // // 导入正确数据
    // importSuccessList() {
    //   let arr = [];
    //   this.successList.forEach((element) => {
    //     arr.push({
    //       subId: element.subId,
    //       shipStatus: 1,
    //       logisticsCompany: element.logisticsCompany,
    //       logisticsCode: element.logisticsNumber,
    //     });
    //   });
    //   arr = JSON.stringify(arr);
    //   this.$ajax({
    //     url: "/hxclass-management/exam/certif/bacth-import",
    //     method: "POST",
    //     params: {
    //       recordId: this.recordId,
    //       generateJson: arr,
    //       type: 2,
    //     },
    //   }).then((res) => {
    //     if (res.code == 200 && res.success) {
    //       this.$message.success(res.message);
    //       this.getData();
    //       this.importVisible = false;
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    // 关联章节
    bindSection(){
      if(this.selectedKeys.length){
        this.$ajax({
          url:'/hxclass-management/exam/library/replace/section?libraryId='+this.selectedRowKeys.join(',')+"&sectionId="+this.selectedKeys[0],
          method:'put'
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.selectedKeys = [];
            this.getManageList();
            this.selectedRowKeys = [];
            this.ChapterVisibel = false;
          }else{
            this.$message.error(res.message);
          }
        })
      }else{
        this.$message.warning('请选择章节！')
      }
    },
    // 关联章节选中
    sectioncheck(e){
      this.selectedKeys = e;
    },
    // 切换课程
    changeCourse(item,index){
      this.courseList.map(ite=>{
        if(ite.courseId == this.courseId){
          ite.isOpen = false;
        }
      })
      this.courseId = item.courseId;
      this.sectionId = '';
      this.tableselectedKeys = [];
      item.isOpen = true;
      this.getManageList();
      if(!item.children.length){
        this.getCourseSection();
      }
    },
    // 树节点点击
    treeSelect(e){
      this.tableselectedKeys = e;
      this.sectionId = e[0];
      this.getManageList();
    },
    cancelRemoval(){
      this.RemovalVisibel = false;
      setTimeout(()=>{
        this.duplicateData = [];
      },1000)
    },
    // 批量关联章节
    relatedChapters(){
      if(this.selectedRowKeys && this.selectedRowKeys.length){
        this.courseList.map((item,index)=>{
          if(item.courseId == this.courseId){
            if(item.children.length){
              this.sectionList = JSON.parse(JSON.stringify(item.children));
              this.ChapterVisibel = true;
            }else{
              this.$message.warning('当前章节暂无章节')
            }
          }
        })
      }else{
        this.$message.warning('请先选择题目')
      }
    },
    modelhandleCancel(){
      this.importVisible=false;
    },
    // 排重
    weightRemoval(){
      // this.duplicateData = [];
      // this.RemovalVisibel = true
      this.$ajax({
        url:"/hxclass-management/exam/library/repeat/list",
        method:"get",
        params:{
          current:this.pageduplicateNumber,
          size:this.pageduplicateSize
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          if(res.data.records.length){
            this.duplicateData = res.data.records;
            this.duplicatetotal = res.data.total;
            this.RemovalVisibel = true;
          }else{
            this.$message.info('无重复数据！');
          }
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 分页
    onPage(e){
      this.pageNumber = e.current
      this.pageSize = e.pageSize
      this.getManageList();
    },
    onPageduplicate(e){
      this.pageduplicateNumber = e.current;
      this.pageduplicateSize = e.pageSize;
      this.weightRemoval();
    },
    // 新建/编辑
    addPage(){
      this.$router.push('/admin/Examination/AddTestQuestions?category='+this.category+'&courseId='+this.courseId)
    },
     // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getManageList();
    },
    // 获取题库列表
    getManageList(){
      this.loading = true
      this.$ajax({
        url:"/hxclass-management/exam/library/list",
        method:"get",
        params:{
          category:this.category, // 大类 1.考试 2.模拟考试 3.小节自测
          courseId:this.courseId, // 课程id	
          difficulty:this.difficulty, // 难度 1.简单 2.一般 3.困难
          sectionId:this.sectionId, // 	章节id
          // sectionName:'', // 章节名称，仅用于管理端列表展示
          title:this.title, // 标题
          type:this.type, // 类型 1.单选 2.多选 3.判断
          current:this.pageNumber,
          size:this.pageSize
        }
      }).then(res=>{
        this.loading = false
        if(res.code == 200 && res.success){
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      })
    },
    // 删除题目
    delManageItem(e){
      this.$ajax({
        url:'/hxclass-management/exam/library/del?libraryId='+e,
        method:'delete'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$message.info('删除成功');
          this.getManageList();
          if(this.duplicateData.length){
            // this.RemovalVisibel = false;
            this.weightRemoval();
          }
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 获取章节列表
    getCourseSection(){
      this.$ajax({
        url:'/hxclass-management/course/tree',
        method:"get",
        params:{
          courseId:this.courseId
        }
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.courseList.map((item,index)=>{
            if(item.courseId == this.courseId){
              this.$set(item,'children',res.data);
              // item.children = res.data;
            }
          })
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // tab切换
    callback(e){
      this.category = e;
      this.getCourseList();
      // this.getManageList();
    },
    select(selectedRowKeys){
      this.selectedRowKeys = selectedRowKeys
      if(this.selectedRowKeys.length == this.tableData.length){
        this.isChecked = true
      }else{
        this.isChecked = false
      }
    },
    selectAll(selectedRows){
      this.selectedRows = selectedRows
    },
    //全选
    onChange(e) {
       if(e.target.checked == true){
         this.isChecked = true
         let libraryId = this.tableData.map(item => {return item.libraryId})
         this.selectedRowKeys = libraryId
       }else{
         this.isChecked = false
         this.selectedRowKeys =[]
       }
    },
    // 获取课程列表
    getCourseList(){
      this.$ajax({
        url:'/hxclass-management/course/exam/box',
        method:'get',
        params:{
          type: this.category == 3?1:this.category,
          pageNum:1,
          pageSize:1000
        }
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.courseId = res.data.records[0].courseId;
          this.courseList = res.data.records.map((item,index)=>{
            item.isOpen = index === 0?true:false;
            item.children = [];
            return item;
          })
          this.getManageList();
          this.getCourseSection();
        }else{
          this.$message.error(res.message);
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getCourseList(); // 获取课程列表
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
/deep/.ant-tree-treenode-switcher-close{
  display: flex !important;
  .ant-tree-node-content-wrapper{
    // flex: 1;
    width: calc(100% - 24px);
    .ant-tree-title{
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
/deep/.ant-tree-treenode-switcher-open{
  // display: flex !important;
  .ant-tree-node-content-wrapper{
    // flex: 1;
    width: calc(100% - 24px);
    .ant-tree-title{
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.duplicate{
  /deep/.ant-pagination{
    bottom: -10px;
  }
}
.btn{
  margin-right: 24px;
}
/deep/ .ant-pagination{
  margin: 0;
  position: relative;
  bottom: 45px;
}
.function{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /deep/ .ant-tabs-bar{
    border-bottom: none;
  }
  .all_boder_btn{
    margin-right: 20px;
  }
}
.table-box{
  .table-left{
    float: left;
    width: 337px;
    margin-right: 40px;
    border: 1px solid #EEEEEE;
    .item{
      border-bottom: 1px solid #EEEEEE;
      cursor: pointer;
      .item-name{
        font-size: 14px;
        color: #333333;
        padding-left: 20px;
        line-height: 45px;
        display: flex;
        align-items: center;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .item-name-active{
        color: #FFFFFF;
        background: #2B6ED4;
      }
    }
    .item:last-child{
      border-bottom:none
    }
    
  }
  .table-right{
    float: left;
    width: calc(100% - 377px);
  }
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.all_content_box{
  .line-item{
    margin-bottom: 20px;
    display: flex;
    .all_left_name{
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .right{
    flex: 1;
    /deep/.ant-input{
      height: 40px;
    }
  }
  textarea.ant-input{
    flex: 1;
  }
}
</style>
